import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyB3HvkoiovcDgD5BuCP68T-xE5aIg1mwtE",
    authDomain: "newmedia-420.firebaseapp.com",
    databaseURL: "https://newmedia-420.firebaseio.com",
    projectId: "newmedia-420",
    messagingSenderId: "724819260757",
    appId: "1:724819260757:web:63789e8f7eb475b8876bdc",
};
// var firebaseConfig = {
//   apiKey: "AIzaSyB3HvkoiovcDgD5BuCP68T-xE5aIg1mwtE",
//   authDomain: "newmedia-420.firebaseapp.com",
//   databaseURL: "https://newmedia-420.firebaseio.com",
//   projectId: "newmedia-420",
//   storageBucket: "newmedia-420.appspot.com",
//   messagingSenderId: "724819260757",
//   appId: "1:724819260757:web:63789e8f7eb475b8876bdc",
//   measurementId: "G-L0KVCRKW86"
// };
// // Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const db = firebase.database();
export default firebaseConfig;